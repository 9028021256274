import React, { PureComponent } from 'react'
import Page from '../components/page'
import path from 'path'
import Image from "next/image"
import { PhotoSlider } from 'react-photo-view';
import '../components/photo-viewer/react-photo-viewer.styl';

import { HOME_DATA } from "../utils/data"
import { getImageFilesInfo } from "../utils/files"


import './index.styl'

export default class Home extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      imageIndex: 0,
      moduleIndex: 0
    }
  }

  show = (moduleIndex, index) => {
    this.setState({
      moduleIndex,
      imageIndex: index,
      isShowViewer: true
    })
  }

  onCloseViewer = () => {
    this.setState({
      isShowViewer: false,
    })
  }

  onIndexChange = (index) => {
    this.setState({
      imageIndex: index,
    })
  }


  render() {
    const { homeList } = this.props
    const { moduleIndex, imageIndex, isShowViewer } = this.state

    const viewerList = (homeList[moduleIndex].imageList || []).map(item => ({src: item.relativeUrl}))

    return (
      <Page pageClassName="page-home" isShowFooter={false}>
        <main className="container home-main">
          {
            homeList.map((moduleOne, moduleIdx) => {
              const { id, imageList } = moduleOne
              return (
                <div key={moduleIdx} id={id}>
                  {imageList.map((item, index) => {
                    const { relativeUrl, width, height, mimeType } = item

                    let oneProps = {}
                    if (moduleIdx <= 1 && index < 2 || (moduleIdx > 1 && index < 1)) {
                      oneProps.priority = true
                    } else {
                      oneProps.loading = 'lazy'
                    }

                    return (
                      <div key={index} className='image-item' onClick={this.show.bind(this, moduleIdx, index)}>
                        <Image
                          layout='responsive'
                          quality={mimeType.includes('gif') ? 50 : 75}
                          alt={relativeUrl}
                          src={relativeUrl}
                          width={width} height={height}
                          style={{ display: 'block' }}
                          {...oneProps}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })
          }
          <div className='image-item'>
            <Image alt={'/home/100-thanks.png'} src={'/home/100-thanks.png'} width={2400} height={1350} style={{ display: 'block' }} />
          </div>
        </main>
        <PhotoSlider
          images={viewerList}
          visible={isShowViewer}
          onClose={this.onCloseViewer}
          index={imageIndex}
          onIndexChange={this.onIndexChange}
        />
      </Page>
    );
  }
}
export async function getStaticProps({ params }) {
  let homeList = []
  HOME_DATA.forEach(item => {
    let relativeUrl = '/home/' + item.path + '/'
    const postsDirectory = path.join(process.cwd(), '/public', relativeUrl)
    const imageList = getImageFilesInfo(postsDirectory, relativeUrl)

    homeList.push({
      ...item,
      imageList
    })
  })

  return {
    props: {
      homeList
    }
  }
}
